import React, {
  useRef,
  useState,
  useContext,
  useEffect,
  useCallback,
} from 'react';
import styled from 'styled-components';
import {Row, Col} from 'antd';
import {Context} from '../../AppContext';
import Billing from '../../Models/Billing';
import {navigate} from 'gatsby';
import Block from '../../Components/Block';
import Field from '../../Components/Field';
import {Edit, Add} from '@styled-icons/material';
import BillingForm from '../../Forms/BillingForm';
import {previousPage} from '../../Utils';
const queryString = require('query-string');

const labels = Billing.labels;

const dbg = {
  id: 'b3951612-984b-48b7-9a5f-65b4e30ac973',
};

export default function BillingDetailPage(props) {
  const {id = dbg.id} = queryString.parse(props.location.search); //order.id
  const app = useContext(Context);
  const [record, setRecord] = useState();

  const getRecord = useCallback(async () => {
    app.actions.setLoading(true);
    try {
      let resp = await app.actions.getBilling({query: {id}});
      setRecord(resp);
    } catch (ex) {
      console.warn(ex);
    }
    app.actions.setLoading(false);
  }, [app.actions, id]);

  useEffect(() => {
    getRecord();
  }, [getRecord]);

  return (
    <Wrapper>
      <div className="action-bar">
        <div
          onClick={() => previousPage(`/billings`)}
          style={{color: '#aaa', cursor: 'pointer'}}>
          {'↼ '}上一頁
        </div>

        <div style={{flex: 1}} />
      </div>
      <Row gutter={[30, 30]}>
        {record && (
          <Col span={12}>
            <Block
              title="廠商資訊"
              actions={[
                {
                  icon: <Edit size="16" color="505050" />,
                  onClick: () => {
                    app.actions.setModal(
                      <BillingForm record={record} onUpdate={getRecord} />,
                    );
                  },
                },
              ]}>
              <Field label={labels['id']}>{record.id}</Field>
              <Field label={labels['customer']}>{record.customer}</Field>
              <Field label={labels['supplier']}>{record.supplier}</Field>
              <Field label={labels['dispatch']}>{record.dispatch}</Field>
              <Field label={labels['quotation']}>{record.quotation}</Field>
              <Field label={labels['pay_period']}>{record.pay_period}</Field>
              <Field label={labels['deadline']}>{record.deadline}</Field>
              <Field label={labels['remind_date']}>{record.remind_date}</Field>
              <Field label={labels['price']}>{record.price}</Field>
              <Field label={labels['note']}>{record.note}</Field>
              <Field label={labels['complete']}>
                {record.complete ? '是' : '否'}
              </Field>
            </Block>
          </Col>
        )}
        <Col span={12}></Col>
      </Row>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 72px 40px;
  background-color: white;

  & > .action-bar {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    & > button {
      margin-left: 10px;
    }
  }
`;
